@font-face {
    font-family: 'ubuntuLight';
    src: url('https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Light.woff2') format('woff2'),
    url('https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ubuntuRegular';
    src: url('https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Regular.woff2') format('woff2'),
    url('https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ubuntuMedium';
    src: url('https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Medium.woff2') format('woff2'),
    url('https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ubuntuBold';
    src: url('https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Bold.woff2') format('woff2'),
    url('https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

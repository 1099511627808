* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100%;
}
.inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(23, 23, 26, 0.08),
    0px 2px 8px 0px rgba(23, 23, 26, 0.05);
}
.card-img {
  margin: auto;
  max-width: 90px;
}
.card-text {
  max-width: 100%;
  display: flex;
  justify-content: center;
}
.card-text-h6 {
  margin: 8px 0px 20px 0px;
  max-width: 150px;
  text-align: center;
  color: #000;
  font-size: 16px;
}
.price {
  color: #3d8705;
  line-height: 23px;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 23px;
}
.img-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 8px;
  /* margin-bottom: 18px; */
}
/* cart list */

.table-btn {
  width: 100%;
}
.table-btn-div {
  border-top: 1px solid #e5e8e8;
  padding-top: 16px;
}
.my-table {
  margin-left: 15px;
}

.paymentCard {
  margin-left: 15px;
}

.maincardtwo .card {
  display: flex;
  min-width: 229px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(23, 23, 26, 0.08),
    0px 2px 8px 0px rgba(23, 23, 26, 0.05);
  padding: 8px 16px;
  margin: 0px 0px 1px;
}
.maincardtwo .price-h6 {
  margin: 16px 0px;
}
img.cross-close {
  width: 20px;
  height: auto;
}
.maincardtwo {
  gap: 0px;
}
.text-input {
  padding: 0px !important;
}
.check {
  padding: 29px 0px 16px 0px !important;
}


/* proceed */
.paymentMethods{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.paypalMethod {
  display: flex;
  justify-content: flex-start;
  padding-left:25px ;
}


/* OrderRowAdmin */

.css-1am6o6o-MuiTableCell-root.MuiTableCell-body{
  text-wrap: nowrap !important;
}